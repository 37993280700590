const dev = 'dev4';
const userDomain = 'angular4';
export const environment = {
	production: false,
	apiDomain: 'https://' + dev + '.api.astrotalk.in/AstroTalk/',
	kundliApi: 'https://' + dev + '.api.astrotalk.in/v1/',
	apiDomainSupport: 'https://' + dev + '.api.astrotalk.in/AstroChat/',
	chatApi: 'https://' + dev + '.api.astrotalk.in/AstrotalkChat/',
	astroMallApi: 'https://' + dev + '.api.astrotalk.in/AstroMall/',
	liveEventDomain: 'https://' + dev + '.api.astrotalk.in/AstrotalkLive/',
	astrologerList: 'https://' + dev + '.api.astrotalk.in/AstroTalk/',


	/*!// preprod 2
	apiDomain: 'http://' + '43.205.100.105:8080' + '/AstroTalk/', // --preprod2
	kundliApi: 'https://api.kundali.astrotalk.com/v1/',
	apiDomainSupport: 'http://' + '3.108.209.162:8080' + '/AstroChat/', // --preprod2
	chatApi: 'http://' + '15.206.255.100:8080' + '/AstrotalkChat/', // --preprod2
	astroMallApi: 'http://' + '65.0.57.85:8080' + '/AstroMall/', // --preprod2
	liveEventDomain: 'http://' + '3.111.162.127:8080' + '/AstrotalkLive/', // --preprod2
	apiDomainForList: 'http://' + '35.154.108.200:8080' + '/AstroTalk/', // --preprod2*/
/*	// preprod 1
	apiDomain: 'http://65.1.228.141:8080/AstroTalk/', // --preprod1
	kundliApi: 'https://api.kundali.astrotalk.com/v1/',
	apiDomainSupport: 'https://api.supportchat.astrotalk.com/AstroChat/', // --preprod1
	chatApi: 'https://api.paidchat.astrotalk.com/AstrotalkChat/', // --preprod1
	astroMallApi: 'http://43.204.63.49:8080/AstroMall/', // --preprod1
	liveEventDomain: 'https://api.live.astrotalk.com/AstrotalkLive/', // --preprod1
	astrologerList: 'http://43.204.104.139:8080/AstroTalk/', // --preprod1*/
/*
	// preprod 1
	apiDomain: 'http://65.1.228.141:8080/AstroTalk/', // --preprod1
	kundliApi: 'https://api.kundali.astrotalk.com/v1/',
	apiDomainSupport: 'https://preprod1.api.astrotalk.in/AstroChat/', // --preprod1
	chatApi: 'https://preprod1.api.astrotalk.in/AstrotalkChat/', // --preprod1
	astroMallApi: 'http://43.204.63.49:8080/AstroMall/', // --preprod1
	liveEventDomain: 'https://api.live.astrotalk.com/AstrotalkLive/', // --preprod1
	astrologerList: 'http://43.204.104.139:8080/AstroTalk/', // --preprod1
*/

	// userDomain: 'https://astrotalk.com/',
	userDomain: 'https://'+ userDomain + '/astrotalk.in/',
	frontDomain: 'https://astrologer.astrotalk.com/',
	apiPrefix: 'https://api.astrologer.com/astrologer/api/v1/',
	assetsUrl: 'https://api.astrologer.com/astrologer/',
	authKey: 'astrologer_dev_token',
	LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
	SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
	CODEYETI_RAZOR_PAY: 'rzp_test_K2FmWkyeoXIGD3',
	MASKYETI_RAZOR_PAY: 'rzp_test_mnLQsJF2nkRjsg',
	ASTROTALK_RAZOR_PAY: 'rzp_test_8z4JnWLwdNHC3e',
	PAYTMMID: 'aGuuxv80528402551811',
	PAYTMWEBSITE: 'WEBSTAGING',
	INDUSTRY_TYPE_ID: 'Retail',
	CHANNEL_ID: 'WEB',
	firebase: {
		apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
		authDomain: 'astroyeti-4bc02.firebaseapp.com',
		databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
		projectId: 'astroyeti-4bc02',
		storageBucket: 'astroyeti-4bc02.appspot.com',
		messagingSenderId: '135983970470',
		appId: '1:135983970470:web:704107c27bf32e2a351c67',
		measurementId: 'G-MB47Q5ZT36'
	}
	/*production: true,
  apiPrefix: 'https://api.astrologer.com/astrologer/api/v1/',
  assetsUrl: 'https://api.astrologer.com/astrologer/',
  authKey: 'astrologer_live_token',
	// apiDomain: 'http://65.1.228.141:8080/AstroTalk/', // pre-prod
  apiDomain: 'https://api.prod.astrotalk.in/AstroTalk/',
  kundliApi: 'https://api.kundali.astrotalk.com/v1/',
  chatApi: 'https://api.paidchat.astrotalk.com/AstrotalkChat/',
  liveEventDomain: 'https://api.live.astrotalk.com/AstrotalkLive/',
  apiDomainSupport: 'https://api.supportchat.astrotalk.com/AstroChat/',
  // LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
  SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
  CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
  MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
  ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
  astroMallApi: 'https://api.astromall.astrotalk.com/AstroMall/',
  PAYTMMID: 'CODEYE84459150770958',
  PAYTMWEBSITE: 'WEBPROD',
  INDUSTRY_TYPE_ID: 'Retail109',
  CHANNEL_ID: 'WAP',
	frontDomain: 'https://astrologer.astrotalk.com/',
	userDomain: 'https://astrotalk.com/',
  firebase: {
    apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
    authDomain: 'astroyeti-4bc02.firebaseapp.com',
    databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
    projectId: 'astroyeti-4bc02',
    storageBucket: 'astroyeti-4bc02.appspot.com',
    messagingSenderId: '135983970470',
    appId: '1:135983970470:web:704107c27bf32e2a351c67',
    measurementId: 'G-MB47Q5ZT36'
  }*/
};
